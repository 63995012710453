// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-cards-js": () => import("./../../../src/pages/en/cards.js" /* webpackChunkName: "component---src-pages-en-cards-js" */),
  "component---src-pages-en-collections-js": () => import("./../../../src/pages/en/collections.js" /* webpackChunkName: "component---src-pages-en-collections-js" */),
  "component---src-pages-en-hashtags-js": () => import("./../../../src/pages/en/hashtags.js" /* webpackChunkName: "component---src-pages-en-hashtags-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-en-terms-of-service-js": () => import("./../../../src/pages/en/terms-of-service.js" /* webpackChunkName: "component---src-pages-en-terms-of-service-js" */),
  "component---src-pages-en-topics-js": () => import("./../../../src/pages/en/topics.js" /* webpackChunkName: "component---src-pages-en-topics-js" */),
  "component---src-pages-en-trending-js": () => import("./../../../src/pages/en/trending.js" /* webpackChunkName: "component---src-pages-en-trending-js" */),
  "component---src-pages-pt-cards-js": () => import("./../../../src/pages/pt/cards.js" /* webpackChunkName: "component---src-pages-pt-cards-js" */),
  "component---src-pages-pt-collections-js": () => import("./../../../src/pages/pt/collections.js" /* webpackChunkName: "component---src-pages-pt-collections-js" */),
  "component---src-pages-pt-hashtags-js": () => import("./../../../src/pages/pt/hashtags.js" /* webpackChunkName: "component---src-pages-pt-hashtags-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-pages-pt-politica-de-privacidade-js": () => import("./../../../src/pages/pt/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-pt-politica-de-privacidade-js" */),
  "component---src-pages-pt-quem-somos-js": () => import("./../../../src/pages/pt/quem-somos.js" /* webpackChunkName: "component---src-pages-pt-quem-somos-js" */),
  "component---src-pages-pt-search-js": () => import("./../../../src/pages/pt/search.js" /* webpackChunkName: "component---src-pages-pt-search-js" */),
  "component---src-pages-pt-termos-de-servico-js": () => import("./../../../src/pages/pt/termos-de-servico.js" /* webpackChunkName: "component---src-pages-pt-termos-de-servico-js" */),
  "component---src-pages-pt-topics-js": () => import("./../../../src/pages/pt/topics.js" /* webpackChunkName: "component---src-pages-pt-topics-js" */),
  "component---src-pages-pt-trending-js": () => import("./../../../src/pages/pt/trending.js" /* webpackChunkName: "component---src-pages-pt-trending-js" */),
  "component---src-templates-en-card-js": () => import("./../../../src/templates/en/Card.js" /* webpackChunkName: "component---src-templates-en-card-js" */),
  "component---src-templates-en-collection-js": () => import("./../../../src/templates/en/Collection.js" /* webpackChunkName: "component---src-templates-en-collection-js" */),
  "component---src-templates-en-tag-js": () => import("./../../../src/templates/en/Tag.js" /* webpackChunkName: "component---src-templates-en-tag-js" */),
  "component---src-templates-en-topic-js": () => import("./../../../src/templates/en/Topic.js" /* webpackChunkName: "component---src-templates-en-topic-js" */),
  "component---src-templates-pt-card-js": () => import("./../../../src/templates/pt/Card.js" /* webpackChunkName: "component---src-templates-pt-card-js" */),
  "component---src-templates-pt-collection-js": () => import("./../../../src/templates/pt/Collection.js" /* webpackChunkName: "component---src-templates-pt-collection-js" */),
  "component---src-templates-pt-story-js": () => import("./../../../src/templates/pt/Story.js" /* webpackChunkName: "component---src-templates-pt-story-js" */),
  "component---src-templates-pt-tag-js": () => import("./../../../src/templates/pt/Tag.js" /* webpackChunkName: "component---src-templates-pt-tag-js" */),
  "component---src-templates-pt-topic-js": () => import("./../../../src/templates/pt/Topic.js" /* webpackChunkName: "component---src-templates-pt-topic-js" */)
}

